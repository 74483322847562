import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import Process from '../images/process.svg'
import Angle from '../images/icons/angle.svg'
import Key from '../images/icons/key.svg'
import Chart from '../images/icons/chart.svg'

import Layout from "../components/Layout"
import Header from '../components/Header'
import Seo from "../components/Seo"
import ContactLauri from "../components/ContactLauri"
import FactoryTour from "../components/FactoryTour"
import SampleProjects from "../components/SampleProjects"

const IndexPage = () => {
  return (
    <Layout>

      <Seo title="Solution" />
      <Header />
      <div className="container-base">
        <div className="section-1-2-center">
          <h1 className="title-xl wel-mb-s">Solutions</h1>
          <p className="body-l">Every construction project goes through several stages. With prefabrication, the sooner you involve an offsite construction specialist, the smoother and cost-effective the whole process will be.</p></div>
      </div>

      <div className="bg-welement-green py-20 px-8 md:px-0">
        <div className="bg-welement-pink md:w-128 p-16 rounded-lg mx-auto">
          <img src={Process} alt="Icon" className="w-full"></img>
        </div>
      </div>

      <div className="bg-welement-white">
        <div className="container-base">
          <div className="section-1-2-center wel-mb-l">
            <h2 className="title wel-mb-s">Our Solutions</h2>
            <p className="body-l">You need a reliable partner to handle the whole process or a capable prefab factory is missing from your value chain?</p>
          </div>

          <div className="grid sm:grid-cols-3 gap-12 sm:gap-8">
            <div className="text-center">
              <img src={Angle} alt="Icon" className="h-24 mx-auto wel-mb-s" />
              <h3 className="title-s">1. Consultation</h3>
              <p className="wel-mb-s">Do you have a housing concept or a prefab product?</p>
              <Link to="#consultation" className="btn btn-black">Read More</Link>
            </div>

            <div className="text-center">
              <img src={Key} alt="Icon" className="h-24 mx-auto wel-mb-s" />
              <h3 className="title-s">2. Prefab Partner</h3>
              <p className="wel-mb-s">A prefab partner is missing from your supply chain?</p>
              <Link to="#prefab-partner" className="btn btn-black">Read More</Link>
            </div>

            <div className="text-center">
              <img src={Chart} alt="Icon" className="h-24 mx-auto wel-mb-s" />
              <h3 className="title-s">3. Full Solution</h3>
              <p className="wel-mb-s">Do you need a full panelized offsite solution?</p>
              <Link to="#full-solution" className="btn btn-black">Read More</Link>
            </div>
          </div>
        </div>

        <div id="consultation" className="bg-welement-pink px-8 md:px-0">
          <div className="container-base">
            <div className="section-1-2-center wel-mb-l">
              <h2 className="title wel-mb-s">1. Consultation</h2>
              <p className="body-l">Do you have a housing concept or a prefab product that you would like to bring to the market?</p>
            </div>

            <div className="grid sm:grid-cols-2 gap-8 wel-mb-l">
              <div className="bg-welement-white p-8 rounded">
                <h3 className="title-s mb-2">Get Advice</h3>
                <p>Why not involve us in the product development process and utilize our extensive knowledge about CAM/CAD design, architectural optimization, and a Design for Manufacturing and Assembly (DfMA) perspective.</p>
              </div>
              <div className="bg-welement-white p-8 rounded">
                <h3 className="title-s mb-2">Our Experience</h3>
                <p>More often than not, only minor changes in the early stages of design can save a lot of money and time.</p>
              </div>
            </div>

            <div className="grid sm:grid-cols-3 lg:w-3/4 mx-auto gap-12 sm:gap-8 wel-mb-l">
              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-1-1.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Design Support"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Design Support</h3>
                <p>DfMA Knowledge
                  Architectural Optimisation
                  Mass Customization</p>
              </div>
              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-1-2.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Offsite Supply Chain Setup"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Offsite Supply Chain Setup</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-1-3.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Prefab Product Development"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Prefab Product Development</h3>
              </div>
            </div>
            <div className="text-center bg-welement-white p-8 rounded sm:w-1/2 mx-auto">
              <h4 className="title-s mb-2">Our Expertise</h4>
              <ul>
                <li>DfMA Knowledge</li>
                <li>Architectural Optimization</li>
                <li>Offsite Supply Chain Set-up</li>
                <li>Prefab Product Development</li>
              </ul>

            </div>
          </div>
        </div>

        <ContactLauri />

        <div id="prefab-partner" className="bg-welement-pink px-8 md:px-0">
          <div className="container mx-auto pt-20 pb-20">
            <div className="w-2/3 mx-auto text-center wel-mb-l">
              <h2 className="title wel-mb-s">2. Prefab Partner</h2>
              <p className="body-l">A prefab partner is missing from your supply chain?</p>
            </div>

            <div className="grid grid-cols-2 gap-8 wel-mb-l">
              <div className="bg-welement-white p-8 rounded">
                <h3 className="title-s mb-2">Client profile</h3>
                <p>You know the prefab process and you have most of the bases like Planning, Structural Design and Assembly covered.</p>
              </div>
              <div className="bg-welement-white p-8 rounded">
                <h3 className="title-s mb-2">Our Offer</h3>
                <p>We can help you with the final stages of Production Design and Element Production using ultra precise German machinery.</p>
              </div>
            </div>

            <div className="grid grid-cols-4 mx-auto gap-8 wel-mb-l">
              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-3-1.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Design"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Design</h3>
              </div>
              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-3-2.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Prefabrication"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Prefabrication</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-3-3.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Window Assembly"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Window Assembly</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-3-4.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Packaging &amp; Transport"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Packaging &amp; Transport</h3>
              </div>

            </div>

            <div className="text-center bg-welement-white p-8 rounded sm:w-1/2 mx-auto">
              <h4 className="title-s mb-2">PROJECT TYPES</h4>
              <ul className="mb-8">
                <li>Apartment buildings</li>
                <li>Rowhouses</li>
                <li>Private houses</li>
                <li>Facade systems</li>
              </ul>

              <h4 className="title-s mb-2">FACTORY CAPABILITIES</h4>
              <ul>
                <li>Terrace &amp; balcony</li>
                <li>Roof elements</li>
                <li>Ceiling elements</li>
                <li>Floor elements</li>
                <li>Internal wall elements</li>
                <li>Facade &amp; external wall elements</li>
              </ul>

            </div>
          </div>

        </div>

        <ContactLauri />

        <div id="full-solution" className="bg-welement-pink px-8 md:px-0">
          <div className="container mx-auto pt-20 pb-20">
            <div className="w-2/3 mx-auto text-center wel-mb-l">
              <h2 className="title wel-mb-s">3. Full Panelized Solution</h2>
              <p className="body-l">Do you need a full panelized offsite solution? We can help you.</p>
            </div>

            <div className="grid grid-cols-2 gap-8 wel-mb-l">
              <div className="bg-welement-white p-8 rounded">
                <h3 className="title-s mb-2">LEVEL OF PREFABRICATION</h3>
                <p>We help you define the best level of prefabrication for the elements.</p>
              </div>
              <div className="bg-welement-white p-8 rounded">
                <h3 className="title-s mb-2">SCOPE &amp; DELIVERY</h3>
                <p>We help you establish the most suitable scope of delivery and assembly for your project.</p>
              </div>
            </div>

            <div className="grid grid-cols-3 w-3/4 mx-auto gap-8 wel-mb-l">
              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-3-1.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Design"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Design</h3>
                <p className="body-s">Structural Design<br />
                  Static Calc<br />
                  Prefab Design</p>
              </div>
              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-3-2.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Prefabrication"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Prefabrication</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-3-3.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Window Assembly"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Window Assembly</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-3-4.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Packaging &amp; Transport"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Packaging &amp; Transport</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-3-5.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Assembly"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Assembly</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../images/solutions/grid-3-6.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Outside Finishing"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Outside Finishing</h3>
              </div>

            </div>

            <div className="text-center bg-welement-white p-8 rounded sm:w-1/2 mx-auto">
              <h4 className="title-s mb-2">PROJECT TYPES</h4>
              <ul className="mb-8">
                <li>Apartment buildings</li>
                <li>Rowhouses</li>
                <li>Private houses</li>
                <li>Facade systems</li>
              </ul>

              <h4 className="title-s mb-2">FACTORY CAPABILITIES</h4>
              <ul>
                <li>Terrace &amp; balcony</li>
                <li>Roof elements</li>
                <li>Ceiling elements</li>
                <li>Floor elements</li>
                <li>Internal wall elements</li>
                <li>Facade &amp; external wall elements</li>
              </ul>

            </div>
          </div>

        </div>

        <ContactLauri />

        <FactoryTour />

        <div className="bg-welement-white">
          <SampleProjects />
        </div>


      </div >

    </Layout >
  )

};

export default IndexPage;
